@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

a:visited {
  color: inherit;
}

a.active {
  background: white;
  border-radius: 5px;
  color: var(--chakra-colors-brand-darkblue);
}

a.active-ctm-nav-item {
  background: var(--chakra-colors-cyan);
  color: var(--chakra-colors-cyan-400);
}

.react-pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 2rem 0;
}

.react-pagination-container li {
  margin: 0 0.5rem;
  list-style: none;
}

.react-pagination-container .active-link {
  background: var(--chakra-colors-brand-darkblue);
  color: white;
  border-radius: 5px;
  padding: 0.5rem 1rem;
}
